/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, {useEffect} from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import {useStaticQuery, graphql} from "gatsby"
import Countly from 'countly-sdk-web';

function SEO({description, lang, meta, title}) {
  const {site} = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )
  useEffect(() => {
    // Provide your app key that you retrieved from Countly dashboard
    Countly.app_key = "b7d9262fd3389db9119eccb1da5784ac1d25dbc7";

    // Provide your server IP or name.
    // If you use your own server, make sure you have https enabled if you use
    // https below.
    Countly.url = "https://foongit-a3af5041d228f.flex.countly.com";

    // Start pushing function calls to queue
    // Track sessions automatically (recommended)
    Countly.q.push(['track_sessions']);

    //track web page views automatically (recommended)
    Countly.q.push(['track_pageview']);

    Countly.init();
  }, []);

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script data-goatcounter="https://stats.foongit.com/count" async src="//stats.foongit.com/count.js"></script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
