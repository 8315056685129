import React from 'react';

import logo from '../images/logo-dark.webp';

interface Props {
  width?: number;
  height?: number;
}

const Logo: React.FC<Props> = ({ width, height }) => (
  <img
    alt="foongit logo"
    height={height >= 0 ? `${height}px` : ''}
    width={width >= 0 ? `${width}px` : ''}
    src={logo}
  />
);

export default Logo;
