import { Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import LogoDark from './LogoDark';
import PageLinks from './PageLinks';
import { CgMenu } from 'react-icons/cg';

const Header = ({ className = '' }) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  return (
    <header className={className}>
      <Link to="/" className="logo"><LogoDark height={50} width={125} /></Link>
      <div className="desktop">
        <PageLinks className="links" />
      </div>
      <div className="mobile">
        <CgMenu onClick={() => setDropdownOpen((currentDropdownOpen) => !currentDropdownOpen)} />
        <div className={`dropdown-body ${dropdownOpen ? 'open' : ''}`} onClick={() => setDropdownOpen(false)}>
          <PageLinks className="links" showExtraLinks />
        </div>
      </div>
    </header>
  );
};

export default styled(Header)`
  position: sticky;
  top: 0;
  max-width: 960;
  display: flex;
  justify-content: space-between;
  height: var(--header-height);
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.7);
  background: var(--color-white);
  z-index: 999;

  .desktop {
    display: flex;
  }

  .mobile {
    display: none;
    position: relative;
    margin: auto 0;

    svg {
      font-size: 3rem;
      margin-right: 20px;
    }
    .dropdown-body {
      display: none;
      position: absolute;
      right: 0;
      background: var(--color-white);
      box-shadow: 0px 4px 3px 0px rgba(0,0,0,0.7);
      margin-top: 23px;

      .links {
        display: flex;
        flex-direction: column;
        a {
          margin: 10px 20px;
        }
      }
      &.open {
        display: block;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile{
      display: inline-block;
    }
  }

  .logo {
    display: flex;
    background-color: rgb(114, 82, 255);
    padding: 1.45rem 1.0875rem;
    width: 230px;

    > img {
      margin: auto;
    }
  }

  .links {
    margin: auto 20px;

    a {
      margin-left: 20px;

      color: var(--color-secondary);
      text-decoration: none;

      &.active {
        color: var(--color-accent);
      }
      &:hover {
        color: var(--color-primary);
      }
    }
  }
`;
