import React from 'react';

import Header from './Header';
import Footer from './Footer';

import 'semantic-ui-css/semantic.min.css';
import 'react-awesome-slider/dist/styles.css';
import 'html5-device-mockups';
import './layout.css';

const Layout = ({ children, className = '' }) => {
  return (
    <div className={className}>
      <Header />
      <div className="body">
        <main className="layout">{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export default Layout;
