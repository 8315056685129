import React from 'react';
import styled from 'styled-components';
import { IoLogoInstagram, IoLogoTwitter, IoLogoWhatsapp, IoLogoYoutube, IoMdMail } from 'react-icons/io';
import { OutboundLink } from 'gatsby-plugin-gtag';

import Logo from './Logo';
import PageLinks from './PageLinks';
import payGateBrandLogos from '../images/paygate-card-brand-logos.png';
import ozowBrandLogo from '../images/ozow-brand-logo.png';

const Footer = ({ className = '' }) => (
  <footer className={className}>
    <section className="content">
      <div className="branding">
        <Logo height={60} width={160} />
      </div>
      <PageLinks className="links" light showExtraLinks />
      <div className="contact">
        <a href="mailto:hello@foongit.com">hello@foongit.com</a>
        <div className="social-links">
          <OutboundLink target="_self" href="https://twitter.com/justfoongit">
            <IoLogoTwitter />
          </OutboundLink>
          <OutboundLink target="_self" href="https://instagram.com/justfoongit">
            <IoLogoInstagram />
          </OutboundLink>
          <OutboundLink target="_self" href="https://wa.me/message/MVC7U73BOCFWO1">
            <IoLogoWhatsapp />
          </OutboundLink>
          <OutboundLink target="_self" href="mailto:hello@foongit.com">
            <IoMdMail />
          </OutboundLink>
          {/* <OutboundLink href="https://foongit.com">
            <IoLogoYoutube />
          </OutboundLink> */}
        </div>
      </div>
    </section>
    <section className="copywrite">
      <div />
      <div>
        <span>© {new Date().getFullYear()} by foongit (Pty) Ltd</span>
        <br />
        <span>BBBEE Level 1</span>
      </div>
      <div className="payment-brand-logos">
        <img src={payGateBrandLogos} />
        <img src={ozowBrandLogo} />
      </div>
    </section>
  </footer>
);

export default styled(Footer)`
  bottom: 0;
  background: var(--color-accent);
  width: 100vw;

  > .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 20px 0;
    align-items: center;
    line-height: 2;

    > * {
      justify-self: center;
    }

    > .branding {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    > .links {
      display: flex;
      flex-direction: column;

      > a {
        width: max-content;
      }
    }

    > .contact {
      p, a {
        color: var(--color-white);
      }

      .social-links {
        color: var(--color-white);
        font-size: 1.25rem;

        > a {
          cursor: pointer;
          margin-right: 10px;

          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      grid-template-rows: 1fr;
      grid-template-columns: 1fr;
      padding: unset;

      > * {
        align-items: center;
      }
      > .branding, .links {
        display: none;
      }
      > .contact .social-links {
        display: flex;
        justify-content: center;
      }
    }
  }

  > .copywrite {
    background: var(--color-dark-purple);
    color: var(--color-white);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px 0;
    text-align: center;
    align-items: center;
    font-size: .6rem;

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .payment-brand-logos {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;

      img {
        height: 25px;
      }
    }
  }
`;
