import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const PageLinks = ({ className, light = false, showExtraLinks = false }) => (
  <div className={className}>
    <Link to="/" activeClassName="active">Home</Link>
    <Link to="#about" activeClassName="active">About Us</Link>
    <Link to="#download" activeClassName="active">Download</Link>
    <Link to="#contact" activeClassName="active">Contact</Link>
    {showExtraLinks ? (
      <>
        <Link to="/terms-and-conditions" activeClassName="active">Terms & Conditions</Link>
        <Link to="/privacy-policy" activeClassName="active">Privacy Policy</Link>
        <Link to="/faq" activeClassName="active">FAQ</Link>
      </>
    ) : null}
  </div>
);

export default styled(PageLinks)`
  a {
    color: ${({ light }) => light ? 'var(--color-white)' : 'var(--color-secondary)'};
    text-decoration: none;

    &.active {
      color: var(--color-accent);
    }
    &:hover {
      color: var(--color-primary);
    }
  }
`;
